<template>
  <div>
    <el-button @click="initBSP">login</el-button>
<!--    <iframe v-if="url" :src="url" frameborder="0"></iframe>-->
  </div>
</template>
<script>

export default {
  data() {
    return {
      url:null,
      appId:'946446659901805'
    };
  },
  beforeMount() {
    // const arr=window.location.hash.split('code=');
    // if(arr.length>1){
    //   this.onCode( arr[1])
    // }
  },
  methods: {
    onCode(code){
      console.log(code);
      // 发送消息给主窗口
      window.opener.postMessage({
        code
      }, window.location.origin); // 替换为你的域名

    },
    testFbLogin(){

     // --------------------------facebook登录测试------------------------------
      const login = () => {
        if(!window.FB) return;
        // 检查用户登录状态
        window.FB.getLoginStatus(function (response) {
          if (response.status !== 'connected') {
            // 未登录，fb会让用户登录
            window.FB.login((res) => {
              // 不管用户有没有登录都会有res
              console.log('FB.login', res.status)
              if(res.status==="connected"){
               const accessToken= res.authResponse.accessToken
              }
            },{scope: 'email,public_profile,pages_show_list,pages_messaging,pages_read_engagement'})
          } else {
            // 已登录过，fb直接返回response
            console.log('FB.getLoginStatus已登录', response)
            if(response.status==="connected"){
              const accessToken= response.authResponse.accessToken
            }
          }
        })
      }
      window.fbAsyncInit = function() {
        window.FB.init({
          appId      : this.appId,
          cookie     : true,
          xfbml      : true,
          version    : 'v18.0'
        });

        window.FB.AppEvents.logPageView();


        login();
      };

      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      login();
      console.log('------------------------facebook-jssdk--------------------------------')



    },
//     testInsLogin(){
//      this.url='https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=561130053054853&redirect_uri=https://care-admin.topkee.top/instagram/code.html&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish'
//       // this.url='http://localhost:8080/#/test?code=1111'
// // code
//       // 打开一个新的子窗口
//       let newWindow = window.open(this.url, 'newWindowName', 'width=600,height=750');
//       // 你可以在新窗口对象上调用方法，例如 focus() 将焦点设置到新窗口
//       newWindow.focus();
//       // 监听来自子窗口的消息
//       window.addEventListener('message', function(event) {
//         // 出于安全考虑，检查消息的来源
//         // 在这里，你应该使用你的实际域名替换 'http://your-domain.com'
//         // 注意：如果你是在本地测试，你可能需要使用 'file://' 或者 localhost
//         if (event.origin !== window.location.origin) {
//           console.warn('Message received from unauthorized origin:', event.origin);
//           return;
//         }
//         // 更新主窗口的内容
//         // document.getElementById('message').textContent = 'Received message: ' + event.data;
//         if(event.data.code){
//           console.log('Instagram授权登录code',event.data.code)
//         }
//         // 可选：关闭子窗口（根据需求来决定是否关闭）
//         newWindow?.close();
//       }, false);
//     },
    initBSP() {
      // this.testInsLogin();
      this.testFbLogin();
    }
  },
};
</script>
